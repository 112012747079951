<template>
  <CustomerAccountComp />
</template>


<script>
export default {
  name: "CustomerAccount",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Customer Account", path:"/customer-accounts", isActive: true}] 
    }
  },
  components: {
    CustomerAccountComp: () => import("../../src/components/customer_accounts/CustomerAccComp"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>